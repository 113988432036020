




































import {Component, Vue} from 'vue-property-decorator';
import ViewDesign from "@/components/global/ViewDesign.vue";

@Component({  components: {ViewDesign}})
export default class InstallPwa extends Vue {
    private deferredPrompt: any = undefined;
    private showInstallButton: boolean = false;
    private userChoicePrompt: string | undefined = undefined;

    mounted() {
        try {
            window.addEventListener("beforeinstallprompt", this.beforeInstall);
        } catch {
        }
    }

    destroyed() {
        try {
            window.removeEventListener("beforeinstallprompt", this.beforeInstall);
        } catch {
        }
    }

    private installPwa(): void {
        try {
            if (this.deferredPrompt) {
                this.showInstallButton = false;
                this.deferredPrompt.prompt();
                // Wait for the user to respond to the prompt
                this.deferredPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {

                    } else {

                    }
                    this.deferredPrompt = undefined;
                });
            }
        } catch {
        }
    }

    private beforeInstall(e: any): void {
        try {
            e.preventDefault();
            // Stash the event so it can be triggered later.
            this.deferredPrompt = e;
            // Update UI to notify the user they can add to home screen
            this.showInstallButton = true;
        } catch {
        }
    }
}

